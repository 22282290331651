import { commonStyles, theme } from 'styles';

export const colorStatus = (props) => {
  const { type = 'primary', onClick } = props;

  const colorStatusTypeMap = {
    primary: {
      backgroundColor: theme.primary,
    },
    info: {
      backgroundColor: theme.infoLight,
    },
    blue: {
      backgroundColor: theme.info,
    },
    pink: {
      backgroundColor: theme.pink,
    },
    error: {
      backgroundColor: theme.errorLight,
    },
    red: {
      backgroundColor: theme.error,
    },
    success: {
      backgroundColor: theme.successLight,
    },
    green: {
      backgroundColor: theme.success,
    },
    warning: {
      backgroundColor: theme.warningLight,
    },
    orange: {
      backgroundColor: theme.warning,
    },
    default: {
      color: theme.gray400,
      backgroundColor: theme.white,
    },
  };

  return [
    colorStatusTypeMap[type],
    {
      color: theme.primaryLight,
      display: 'inline-block',
      fontWeight: 500,
      padding: '4px 12px',
      borderRadius: 4,
      height: 'auto',
      fontSize: '1.2rem',
      border: type === 'default' && `1px solid ${theme.gray400}`,
      cursor: onClick ? 'pointer' : 'default',
    },
    ...commonStyles(props),
  ];
};
