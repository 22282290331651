import liposomes from 'assets/images/liposomes-icon.svg';
import melatonin from 'assets/images/melatonin-icon.svg';
import cinnamon from 'assets/images/cinnamon.svg';
import q10 from 'assets/images/q-10-icon.svg';
import retinol from 'assets/images/retinol-icon.svg';
import zeolites from 'assets/images/zeolites-icon.svg';

export const ingredientsData = [
  {
    id: 1,
    icon: cinnamon,
    title: 'fruitEnzymes',
    description: 'fruitEnzymesDescription',
  },
  {
    id: 2,
    icon: melatonin,
    title: 'vitamins',
    description: 'vitaminsDescription',
  },
  {
    id: 3,
    icon: liposomes,
    title: 'mineralsPca',
    description: 'mineralsPcaDescription',
  },
  {
    id: 4,
    icon: retinol,
    title: 'glycolicAcid',
    description: 'glycolicAcidDescription',
  },
  {
    id: 5,
    icon: q10,
    title: 'panthenolAndAloeVera',
    description: 'panthenolAndAloeVeraDescription',
  },
  {
    id: 7,
    icon: zeolites,
    title: 'oliveLeafExtractAndLicoriceExtract',
    description: 'oliveLeafExtractAndLicoriceExtractDescription',
  },
];
