import { AFFILIATE_STATUS } from 'enums';
import cleansingGel from 'assets/images/cleaning-gel.jpg';
import fillerSerum from 'assets/images/filler-serum.jpg';
import eyeCream from 'assets/images/eye.jpg';
import nightCream from 'assets/images/night-cream.jpg';
import dayCream from 'assets/images/day-cream.svg';

export const affiliateStatusMap = {
  [AFFILIATE_STATUS.PENDING]: {
    color: 'pink',
    title: 'sitTight',
    description: 'weAreStillEvaluatingYourApplication',
  },
  [AFFILIATE_STATUS.APPROVED]: {
    color: 'success',
  },
  [AFFILIATE_STATUS.REJECTED]: {
    color: 'error',
    title: 'yourApplicationHasBeenRejected',
    description: 'weRegretToInformYouButYourApplicationHasBeenRejected',
  },
};

export const affiliateProducts = [
  {
    id: 1,
    image: cleansingGel,
    title: 'liposomalCleansingGel',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 2,
    image: fillerSerum,
    title: 'liposomalFillerSerum',
    link: 'onevita.eu/r/loo1fasvs-2387urg-w25',
  },
  {
    id: 3,
    image: eyeCream,
    title: 'liposomalEyeCream',
    link: 'onevita.eu/r/52316vD-3b-gag-w25',
  },
  {
    id: 4,
    image: dayCream,
    title: 'liposomalDayCream',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 5,
    image: nightCream,
    title: 'liposomalNightCream',
    link: 'onevita.eu/r/bl4-522xasvs-23b-g7v25b',
  },
  {
    id: 6,
    image: nightCream,
    title: 'liposomalCalmingAndHydratingMask',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 7,
    image: nightCream,
    title: 'liposomalMetabolicActivatingMask',
    link: 'onevita.eu/r/52316vD-3b-gag-w25',
  },
];
