import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Col, Image, Row, BecomeAffiliateCart, DiscoverBeauty } from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTINE_PRODUCTS_DATA } from 'enums';
import routineHeaderImage from 'assets/images/routine-header-image.jpg';
import { skinCareContainer } from './styles';

const SkinCare = ({ isMobile }) => {
  const history = useHistory();
  const productDetails = (product) => ALL_PRODUCTS_MOCK_DATA?.find((el) => el.id === product.id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const informationColumn = (product) => (
    <Col xs={12} sm={12} md={5} lg={4} xl={4} className="products-info-container">
      {isMobile ? (
        <div className="mobile-product-title">
          <div className="step">{product.id}</div>
          <h5 className="title">{getText(product?.title)}</h5>
        </div>
      ) : (
        <>
          <h5 className="title">{getText(product?.title)}</h5>
          <hr className="horizontal-line" />
        </>
      )}
      <p className="description">{getText(product?.description)}</p>
      <Button
        type="secondary"
        className="product-button"
        onClick={() => history.push({ pathname: `/products/${product.id}`, state: productDetails(product) })}>
        {getText(product.buttonText)}
      </Button>
    </Col>
  );

  return (
    <div css={skinCareContainer}>
      <Image src={routineHeaderImage} className="header-image" />

      <div className="header-info-container">
        <h3 className="title">{getText('fiveStepSystemForYouthfulSkin')}</h3>
        <p className="subtitle">{getText('transformYourDailySkincare')}</p>
      </div>

      <div className="routine-steps-container">
        <h4 className="routine-title">{startCase(getText('learnRoutineBelow'))}</h4>
        <div className="routine-container">
          <Row gap={24} className="bordered-container">
            {ROUTINE_PRODUCTS_DATA.map((el, ind) => (
              <div key={ind}>
                {!isMobile && <div className="step">{el.id}</div>}
                {isMobile ? (
                  <div className="mobile-content-container">
                    {informationColumn(el)}
                    <Image src={el.image} className="mobile-images" />
                  </div>
                ) : (
                  <div>
                    {el.isRightImage ? (
                      <div className="product-container">
                        <Col xs={12} sm={12} md={1} lg={2} xl={2} />
                        {informationColumn(el)}
                        <Col xs={12} sm={12} md={1} lg={1} xl={2} />
                        <Col xs={12} sm={12} md={5} lg={5} xl={4}>
                          <Image src={el.image} width={315} height={324} />
                        </Col>
                      </div>
                    ) : (
                      <div className="product-container">
                        <Col xs={12} sm={12} md={1} lg={1} xl={2} />
                        <Col xs={12} sm={12} md={5} lg={5} xl={4}>
                          <Image src={el.image} width={315} height={324} />
                        </Col>
                        <Col xs={12} sm={12} md={1} lg={1} xl={2} />
                        {informationColumn(el)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <Button type="primary" className="buy-routine" onClick={() => {}}>
              {getText('buyRoutine')}
            </Button>
          </Row>
        </div>
      </div>

      <hr className="horizontal-end-routine-line" />

      <DiscoverBeauty />
      <BecomeAffiliateCart />
    </div>
  );
};

export default SkinCare;
