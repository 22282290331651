import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Icon, Image, Button, Skeleton, Tooltip } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { productContainer } from './styles';

const ProductSliderCart = ({ product }) => {
  const history = useHistory();
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (price) =>
    price &&
    printPrice({
      fiat: Number(price),
      fiatOriginCurrency: DEFAULT_CURRENCY.code,
      fiatDesiredCurrency: selectedCurrency?.code,
    });

  return isEmpty(product) ? (
    <Skeleton width={'100%'} height={645} />
  ) : (
    <div css={productContainer}>
      <div className="product-container">
        <Image src={product.image} className="product-image" />
        <div className="product-info-container">
          <p className="product-title">{getText(product.title)}</p>
          <div className="price-icon-quantity-container">
            <p className="product-price">{convertedToLocalCurrencyPrice(product.price)}</p>
            <Tooltip content={getText('hello')} placement="bottom" noArrow className="info-tooltip">
              <Icon iconName="info" size={16} className="icon" />
            </Tooltip>
            <p className="product-quantity">
              {product?.numberOfQuantity
                ? getText('quantityMl', { quantity: product.quantity, numberOfQuantity: product.numberOfQuantity })
                : getText('ml', { quantity: product.quantity })}
            </p>
          </div>
          <div className="action-container">
            <Button type="default" className="button-default" onClick={() => {}}>
              {getText('addToCart')}
            </Button>
            <Button
              type="link"
              className="button-link"
              onClick={() => history.push({ pathname: `/products/${product.id}`, state: product })}>
              {getText('learnMore')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSliderCart;
