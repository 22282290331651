import { useState } from 'react';
import { useEventListener } from './useEventListener';

export const useWindowSize = (initialWidth, initialHeight) => {
  const [windowSize, setWindowSize] = useState({
    width: initialWidth ?? window.innerWidth,
    height: initialHeight ?? window.innerHeight,
  });

  useEventListener('resize', () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  });

  return windowSize;
};
