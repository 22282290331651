export const ROUTES = {
  // private routes
  MyAffiliates: '/my-affiliates',

  // public routes
  AuthorizationCallback: '/authorization-callback',
  BecomeAnAffiliate: '/become-an-affiliate',
  BecomeAnAffiliateApply: '/become-an-affiliate/apply',
  BrandStory: '/brand-story',
  Delivery: '/delivery',
  ForgotPassword: '/forgot-password',
  Home: '/home',
  Ingredients: '/ingredients',
  Logout: '/logout',
  Login: '/login',
  PrivacyPolicy: '/privacy-policy',
  Products: '/products',
  ProductDetails: '/products/:productId',
  SkinCare: '/skin-care',
  TermsAndConditions: '/terms-and-conditions',
};
