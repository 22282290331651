import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import {
  checkButtonContainer,
  checkButtonIcon,
  checkButtonContainerStyles,
  errorStyles,
  helpContent,
  helpIcon,
} from './styles';

const CheckButton = (props) => {
  const { children, onError, value, disabled, onChange, className, required, isTouched, radio } = props;

  const [isChecked, setIsChecked] = useState(!!value);
  const [error, setError] = useState(false);

  const iconName = radio ? 'adjust' : 'check';

  useEffect(() => {
    setIsChecked(!!value);
  }, [!!value]);

  useEffect(() => {
    isTouched && checkForError(isChecked);
  }, [isTouched]);

  const handleChange = () => {
    if (disabled) return;
    setIsChecked((prev) => {
      isFunction(onChange) && onChange(!prev);
      checkForError(!prev);
      return !prev;
    });
  };

  const checkForError = (err) => {
    if (required && !err && isFunction(onError)) {
      onError({ msg: 'Required' });
      setError(true);
    } else setError(false);
  };

  return (
    <div>
      <div css={checkButtonContainerStyles} {...(className && { className })}>
        <div
          role="checkbox"
          tabIndex={0}
          css={checkButtonContainer(!!children, isChecked, props)}
          onClick={handleChange}
          aria-checked={isChecked}>
          <Icon material iconName={iconName} color="white" css={checkButtonIcon(isChecked)} size={18} />
        </div>
        <span className="label">{children}</span>
      </div>
      {error && (
        <div css={helpContent}>
          <Icon material iconName="error" css={helpIcon} size={14} />
          <span css={errorStyles}>Required!</span>
        </div>
      )}
    </div>
  );
};

CheckButton.displayName = 'CheckBox';

CheckButton.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  children: PropTypes.any,
  radio: PropTypes.bool,
};

export default CheckButton;
