import PropTypes from 'prop-types';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Tooltip } from 'components';
import { slideInLeftAnimation } from 'styles';
import { useNavigationControls } from '../NavigationContext';
import {
  tooltipContainer,
  menuItem,
  menuItemContent,
  menuItemText,
  tooltipContent,
  subMenuList,
  menuSubItem,
  subMenusTreeContainer,
  menuItemRightIcon,
} from './styles';

export const MenuItem = ({ label, icon, url, state, iconProps, subMenus = [], isOpen, externalComponent }) => {
  const hasSubMenus = isArray(subMenus) && !!subMenus.length;
  const location = useLocation();
  const { getText } = useTranslations();
  const { closeNavigation, open, close, isOpen: openNav } = useNavigationControls();

  const mainMenuContent = (
    <div css={menuItemContent} role="button" tabIndex={0} onClick={() => closeNavigation()}>
      <Icon material size="2.3rem" iconName={icon} {...iconProps} />
      {isOpen && <span css={menuItemText}>{getText(`${label}`)}</span>}
    </div>
  );

  return hasSubMenus ? (
    matchPath(location.pathname, url, state) && isOpen ? (
      <>
        <NavLink to={{ pathname: url, state: state }} css={menuItem} className="active">
          {mainMenuContent}
          {isOpen && (
            <Icon
              material
              iconName={openNav ? 'expand_less' : 'expand_more'}
              color="black"
              css={menuItemRightIcon}
              onClick={() => (openNav ? close() : open())}
            />
          )}
        </NavLink>
        <section css={subMenusTreeContainer}>
          {subMenus.map((el, ind) => (
            <NavLink
              key={el.url}
              to={{ pathname: el.url, state: el.state }}
              className={matchPath(location.pathname, { path: el.url, exact: false, strict: false }) ? 'active' : ''}
              css={[menuSubItem, slideInLeftAnimation({ translateStart: '-200px, 0, 0', delay: 0.2 * ind })]}
              onClick={() => closeNavigation()}>
              {getText(`${el.label}`)}
            </NavLink>
          ))}
        </section>
      </>
    ) : (
      <Tooltip
        noArrow
        content={({ setIsOpen }) => (
          <div css={subMenuList}>
            {subMenus.map((el) => (
              <NavLink
                key={el.url}
                to={{ pathname: el.url, state: el.state }}
                css={menuSubItem}
                onClick={() => setIsOpen(false)}>
                {getText(`${el.label}`)}
              </NavLink>
            ))}
          </div>
        )}
        placement="right-start"
        css={tooltipContainer}
        contentClassName={tooltipContent}
        zIndex={1500}>
        <NavLink to={{ pathname: url, state: state }} css={menuItem}>
          {mainMenuContent}
          {isOpen && <Icon material iconName="expand_more" color="black" css={menuItemRightIcon} />}
        </NavLink>
      </Tooltip>
    )
  ) : url ? (
    <NavLink to={{ pathname: url, state: state }} css={menuItem}>
      {mainMenuContent}
    </NavLink>
  ) : (
    externalComponent
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  state: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  onSelect: PropTypes.func,
  subMenus: PropTypes.array,
  isOpen: PropTypes.bool,
  externalComponent: PropTypes.any,
};
