import { getToken } from '@oneecosystem/authenticate';
import { getText } from '@veraio/strank';
import { useStore, createStore, showApiError, showError } from 'components';
import { USER_STATUS } from 'enums';
import { getOneLifeProfile } from 'services';

const userStore = createStore({
  isAuthenticated: !!getToken(),
  userInfo: null,
  accounts: [],
  affiliates: {
    status: 'Pending',
    createdDate: '2024-06-10T07:31:15.339996Z',
    modifiedDate: '2024-06-10T07:31:15.339996Z',
  },
});

export const initializeUser = async () => {
  const [lifeUser, lifeErr] = await getOneLifeProfile();
  if (lifeErr) return showApiError(lifeErr);
  if (lifeUser?.status !== USER_STATUS.Active) return showError(getText('userProfileIsNotActive'));

  userStore.setState({
    userInfo: lifeUser,
    accounts: lifeUser?.accounts?.map((el) => ({ ...el, displayName: `#${el.id} ${el.nickName}` })),
    isAuthenticated: true,
    affiliates: {
      status: 'Approved',
      createdDate: '2024-06-10T07:31:15.339996Z',
      modifiedDate: '2024-06-10T07:31:15.339996Z',
    },
  });
};

export const useUserStore = (...args) => useStore(userStore, ...args);
