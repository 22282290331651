import coinIcon from 'assets/images/coin-icon.svg';
import deliveryIcon from 'assets/images/delivery-icon.svg';
import educationIcon from 'assets/images/education-icon.svg';
import growingIcon from 'assets/images/growing-icon.svg';

export const affiliateData = [
  {
    id: 1,
    image: coinIcon,
    title: 'directAndNetworkBonuses',
    description: 'earnLucrativeRewardsForYourDirectSales',
  },
  {
    id: 2,
    image: deliveryIcon,
    title: 'freeDelivery',
    description: 'enjoyComplimentaryDelivery',
  },
  {
    id: 3,
    image: educationIcon,
    title: 'trainingAndEducation',
    description: 'gainExclusiveAccessToOurComprehensiveTraining',
  },
  {
    id: 1,
    image: growingIcon,
    title: 'growingOpportunity',
    description: 'embarkOnJourneyWithEndlessPossibilities',
  },
];
