import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Button, Carousel, Col, Row } from 'components';
import { ALL_PRODUCTS_MOCK_DATA } from 'enums';
import { carouselProductsContainer } from './styles';

const CarouselProducts = ({ products, isMobile }) => {
  const { getText } = useTranslations();
  const history = useHistory();
  const [active, setActive] = useState(1);
  const activeInformation = products?.find((el) => el.id === active + 1);
  const productDetails = ALL_PRODUCTS_MOCK_DATA?.find((el) => el.id === active + 1);

  const handleActiveChange = (currentImage) => {
    setActive(currentImage);
  };

  const carouselColumn = () => (
    <Col xs={12} sm={12} md={6} className="carousel-container-home">
      <div className="step-container">
        <h1 className="step">{activeInformation.step}</h1>
      </div>
      <Carousel
        imageSize="contain"
        width={400}
        height={400}
        images={products?.map((el) => el.image)}
        className="carousel-products"
        onChange={handleActiveChange}
      />
    </Col>
  );

  const informationColumn = () => (
    <Col xs={12} sm={12} md={6} className="products-info-home-container">
      <h5 className="title">{getText(activeInformation?.title)}</h5>
      <hr className="horizontal-line" />
      <p className="description">{getText(activeInformation?.description)}</p>
      <Button
        type="secondary"
        className="product-button"
        onClick={() => history.push({ pathname: `/products/${activeInformation.id}`, state: productDetails })}>
        {getText(activeInformation.buttonText)}
      </Button>
    </Col>
  );

  return (
    <Row css={carouselProductsContainer}>
      {isMobile ? carouselColumn() : informationColumn()}
      {isMobile ? informationColumn() : carouselColumn()}
    </Row>
  );
};

export default CarouselProducts;
