import flowerIcon from 'assets/images/flower-icon.svg';
import starIcon from 'assets/images/star-icon.svg';
import flagStarIcon from 'assets/images/flag-star-icon.svg';
import lightBulbIcon from 'assets/images/cinnamon.svg';
import eyeIcon from 'assets/images/q-10-icon.svg';

export const liposomesData = [
  { id: 1, image: flowerIcon, title: 'liposomesAreCarriersOfYouth', description: 'theyDeliverToSkinCells' },
  { id: 2, image: starIcon, title: 'liposomesAreFutureOfCosmetics', description: 'theyAreNearFuture' },
];

export const liposomalIngredientsData = [
  { id: 1, image: flagStarIcon, title: 'liposomes', description: 'harnessPowerOfLiposomeTechnology' },
  { id: 2, image: lightBulbIcon, title: 'vitaminC', description: 'liposomeVitaminCBestSkincareIngredient' },
  { id: 3, image: eyeIcon, title: 'cinnamon', description: 'liposomeCinnamonNotJustSweet' },
  { id: 4, image: flagStarIcon, title: 'melatonin', description: 'protectAndRefreshYourSkinWithMelatonin' },
  { id: 5, image: lightBulbIcon, title: 'retinol', description: 'transformYourSkinByRenewingCells' },
  { id: 6, image: eyeIcon, title: 'qTen', description: 'experienceLifeChangingHealthAndBeautyBoosts' },
  { id: 7, image: flagStarIcon, title: 'caffeine', description: 'energizeYourSkinToNewHorizonsWithCaffeine' },
  { id: 2, image: lightBulbIcon, title: 'zeolites', description: 'turnBackClockOnAgingAndExperience' },
];
