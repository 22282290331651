import { useState, useRef, useLayoutEffect, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Modal, Image, Button, ImageGallery } from 'components/ui';
import Slide from './slide';
import { slider, sliderWrapper, dotsContainer, indicatorDot, arrowsLeft } from './styles';

const Carousel = forwardRef((props, ref) => {
  const {
    images,
    startImage,
    width: widthProp,
    height,
    imageSize,
    withoutArrows,
    withFullscreen = false,
    withShowAllImages = false,
    withCustomDots,
    customDots,
    className,
    onChange,
  } = props;
  const { getText } = useTranslations();
  const [currentIndex, setCurrentIndex] = useState(startImage || 0);
  const [width, setWidth] = useState(widthProp || 0);
  const [showFullScreen, setShowFullscreen] = useState(null);
  const containerRef = useRef();
  const modalRef = useRef();
  const allImagesModalRef = useRef();

  useImperativeHandle(ref, () => ({
    currentIndex,
    changeCurrentIndex: (index) => handleIndicator(index),
    handleGetDotsTranslate: () => getDotsTranslate(),
    handleNextSlide: () => handleNextSlide,
    handlePrevSlide: () => handlePrevSlide,
  }));

  useEffect(() => {
    setCurrentIndex(currentIndex);
    onChange(currentIndex);
  }, [currentIndex]);

  useLayoutEffect(() => {
    setWidth(containerRef.current.clientWidth);
  }, [widthProp]);

  useEffect(() => {
    const handleKeyNext = (e) =>
      (e.key === 'ArrowRight' || e.keyCode === 39) &&
      setCurrentIndex((prevIndex) => (prevIndex !== images.length - 1 ? prevIndex + 1 : 0));
    document.addEventListener('keydown', handleKeyNext);

    return () => {
      document.removeEventListener('keydown', handleKeyNext);
    };
  }, []);

  useEffect(() => {
    const handleKeyPrev = (e) =>
      (e.key === 'ArrowLeft' || e.keyCode === 37) &&
      setCurrentIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : images.length - 1));
    document.addEventListener('keydown', handleKeyPrev);

    return () => {
      document.removeEventListener('keydown', handleKeyPrev);
    };
  }, []);

  const handlePrevSlide = () => {
    if (currentIndex === 0) return;
    setCurrentIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextSlide = () => setCurrentIndex((prevIndex) => (prevIndex !== images.length - 1 ? prevIndex + 1 : 0));

  const handleIndicator = (index) => setCurrentIndex(index);

  const getDotsTranslate = () => {
    switch (true) {
      case currentIndex < 2:
        return 0;
      case currentIndex > images.length - 3:
        return (images.length - 5) * -12;
      default:
        return (currentIndex - 2) * -12;
    }
  };

  const handleShowFullScreen = (image) => {
    setShowFullscreen(image);
    modalRef.current?.open();
  };

  return (
    <>
      <div ref={containerRef} css={slider(props)} {...(className && { className })}>
        <div css={sliderWrapper} style={{ transform: `translateX(${currentIndex * -width}px)` }}>
          {images?.map((image, index) => (
            <Slide
              key={index}
              image={isString(image) ? image : image?.src}
              width={width}
              height={height}
              size={imageSize}
              className="images">
              {image?.content}
              {withFullscreen && (
                <Icon
                  iconName="dialogs"
                  color="white"
                  size={30}
                  className="fullscreen-icon"
                  onClick={() => handleShowFullScreen(image)}
                />
              )}
            </Slide>
          ))}
        </div>
        {withShowAllImages && (
          <Button small type="link" onClick={() => allImagesModalRef.current?.open()} className="all-photos-button">
            <div className="action">
              <p className="label">{getText('allPhotos')}</p>
              <Icon material iconName="expand_circle_right" color="white" className="icon" />
            </div>
          </Button>
        )}
        {!withoutArrows && (
          <div className="arrows-container">
            <Icon
              material
              size={30}
              onClick={handlePrevSlide}
              iconName="chevron_left"
              className="left-arrow"
              css={arrowsLeft(currentIndex === 0)}
            />

            {images?.length > 1 && (
              <Icon material size={30} onClick={handleNextSlide} iconName="chevron_right" className="right-arrow" />
            )}
          </div>
        )}
        {withCustomDots ? (
          customDots(handleIndicator, currentIndex)
        ) : (
          <div css={dotsContainer(withShowAllImages)} className="dots-container">
            <ul className="dots-list" style={{ transform: `translateX(${getDotsTranslate()}px)` }}>
              {images?.map((_, index) => (
                <li
                  key={index}
                  css={indicatorDot(index === currentIndex)}
                  className="indicator-dot"
                  onClick={() => handleIndicator(index)}
                  role="presentation"
                />
              ))}
            </ul>
          </div>
        )}
      </div>
      <Modal fullscreen ref={modalRef} closeIcon>
        <Image src={showFullScreen} width="100%" height="100%" />
      </Modal>
      <Modal fullscreen ref={allImagesModalRef} closeIcon>
        <ImageGallery images={images} carouselView carouselImagesHeight={'calc(100vh - 250px)'} />
      </Modal>
    </>
  );
});

Carousel.propTypes = {
  images: PropTypes.array,
  currentIndex: PropTypes.number,
  startImage: PropTypes.number,
  withoutArrows: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageSize: PropTypes.string,
  withFullscreen: PropTypes.bool,
  withCustomDots: PropTypes.bool,
  withShowAllImages: PropTypes.bool,
  customDots: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default Carousel;
