import { commonStyles } from '.';

export default {
  primary: '#000000',
  primaryLight: '#050505',
  secondary: '#F2F3F5',
  secondaryDark: '#f9f8f7',
  secondaryDarkLight: '#F3F3F5',
  secondaryLight: '#FAFAFC',
  secondaryLighter: '#F3F4F6',
  blue: '#0070D1',
  blueDark: '#212E4B',
  blueLight: '#98CBF6',
  gray: '#5f636f',
  grayLight: '#BABABA',
  whiteGray: '#e9eaf0',
  gray100: '#FAFAFC',
  gray200: '#A7A7A7',
  gray300: '#555555',
  gray400: '#5C5C5C',
  gray500: '#313131',
  gray600: '#D9D9D9',
  gray700: '#CFCFCF',
  gray800: '#414141',
  gray900: '#282828',
  black: '#000000',
  blackLight: '#2E2E36',
  blackLightDark: '#030303',
  black10: 'rgba(0, 0, 0, 0.1)',
  black24: 'rgba(0, 0, 0, 0.24)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black56: 'rgba(0, 0, 0, 0.56)',
  white: '#ffffff',
  white56: 'rgba(255,255,255,0.56)',
  info: '#40AAFB',
  infoLight: '#DAEBFF',
  infoDark: '#0F6EDE',
  warning: '#ffab02',
  warningLight: '#F2D746',
  warningDark: '#9e4a03',
  success: '#00c177',
  successLight: '#97FFA8',
  successDark: '#1A8237',
  error: '#DC3030',
  errorLight: '#FF6C4B',
  errorDark: '#DA1A0E',
  highlight: '#458FFF',
  pink: '#ABABF6',
  pinkDark: '#5666BC',
  boxShadowLight: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
  boxShadow: '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)',
  boxShadowDark: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
  boxShadowDarker: '0px 4px 14px 0px rgba(0, 0, 0, 0.13)',
  boxShadowLighter: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  rootFontSize: 10,
  sm: 576,
  md: 786,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  borderRadiusS: 8,
  borderRadiusM: 16,
  borderRadiusL: 24,
  spaceXXS: 4,
  spaceXS: 8,
  spaceS: 12,
  spaceM: 16,
  spaceL: 20,
  spaceXL: 24,
  spaceXXL: 32,
  spaceXXXL: 48,
  spaceXXXXL: 64,
  lineAwesome: true,
  margin: (value) => ({ margin: value }),
  padding: (value) => ({ padding: value }),
  flex: (value) => ({ flex: value }),
  displayFlex: ({ align, justify, direction, wrap, flow } = {}) => ({
    display: 'flex',
    ...(align && { alignItems: align }),
    ...(justify && { justifyContent: justify }),
    ...(direction && { flexDirection: direction }),
    ...(wrap && { flexWrap: wrap }),
    ...(flow && { flexFlow: flow }),
  }),
  styles: (properties) => commonStyles(properties),
};
