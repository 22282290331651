import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from 'components';
import { fadeInAnimation } from '../../../../styles';
import { inputContainer, inputLabel, inputHelpContainer, inputHelpContent, inputHelpIcon } from './styles';

const InputContainer = (props) => {
  const { isTouched, required, error, validate, label, className, children, notShowLabel } = props;
  const hasError = isTouched && !!error;
  const hasValidation = isFunction(validate) || required;

  return (
    <div css={inputContainer(props)} {...(className && { className })}>
      {!!label && !notShowLabel && (
        <label css={inputLabel(props)} className="input-label">
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}

      <div className="input-container">
        {children}

        {hasValidation && (
          <div css={inputHelpContainer} className="input-help-container">
            {hasError && (
              <div css={[inputHelpContent, fadeInAnimation()]}>
                <Icon material iconName="error" css={inputHelpIcon} size={14} />
                <span>{error.msg}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InputContainer.propTypes = {
  label: PropTypes.string,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  notShowLabel: PropTypes.bool,
  error: PropTypes.any,
  validate: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default InputContainer;
