import { theme } from 'styles';

export const myAffiliateContainer = {
  backgroundColor: theme.black,
  color: theme.white,
  height: '100%',
  padding: '6em 15.25em',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '.title': {
    fontSize: 48,
    marginBottom: 64,
  },

  '.text': {
    fontSize: 18,
    marginBottom: 24,
  },

  '.affiliate-badge': {
    padding: '8px 32px',
    borderRadius: 25,
    fontSize: 16,
    fontWeight: 400,
    color: theme.primaryLight,
  },

  '.affiliate-title': {
    fontWeight: 400,
    fontSize: 32,
    marginBottom: 24,
  },

  '.affiliate-description': {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: 1.6,
  },

  '.affiliate-link': {
    margin: '0 4px',
    color: theme.white,
    fontSize: 22,
    fontWeight: 700,
  },

  '.button': {
    textTransform: 'uppercase',
    padding: '1em 4em',

    '&:hover': {
      borderColor: 'none',
    },

    '&:active': { outline: 'none' },
  },

  '@media (max-width: 1199px)': {
    padding: '6em 9.25em',
  },

  '@media (max-width: 767px)': {
    padding: '6em',
  },

  '@media (max-width: 576px)': {
    padding: '6em 3em',
  },
};

export const myApprovedAffiliateContainer = {
  backgroundColor: theme.secondaryLighter,
  color: theme.primaryLight,
  height: '100%',
  padding: '6em 18.25em',

  '.approved-header-info-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,

    '.approved-header-info': {
      marginRight: 48,

      '.approved-title': {
        fontSize: 48,
        fontWeight: 400,
        marginBottom: 32,
      },

      '.approved-description': {
        fontWeight: 300,
        fontSize: 20,
        lineHeight: 1.6,
      },
    },

    '.sticker-image': {
      width: 300,
      height: 300,

      '@media (max-width: 992px)': {
        width: 200,
        height: 200,
      },
    },

    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },

  '.approved-table-title': {
    fontSize: 32,
    fontWeight: 400,
    marginBottom: 32,
  },

  '.approved-table-container': {
    marginBottom: 40,

    '& > div:nth-of-type(2n)': {
      backgroundColor: theme.secondaryLighter,
    },

    '.approved-table-row': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.white,
      padding: '12px 24px',

      '.approved-table-row-product': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.approved-table-row-product-image': {
          width: 38,
          height: 38,
          marginRight: 8,
        },

        '.approved-table-row-product-title': {
          fontSize: 18,
          textTransform: 'uppercase',
          marginRight: 8,
        },

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },

      '.approved-table-row-link': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.approved-table-row-link-text': {
          fontSize: 13,
          color: theme.pinkDark,
          marginRight: 12,
        },

        '.link-button': {
          textTransform: 'uppercase',

          '.icon-link': {
            transform: 'rotate(-45deg)',
          },

          '&:hover': {
            borderColor: 'none',
          },

          '&:active': { outline: 'none' },
        },

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },

      '@media (max-width: 576px)': {
        flexWrap: 'wrap',
      },
    },
  },

  '.approved-footer-info-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '.approved-footer-info': {
      fontSize: 18,
      lineHeight: 1.6,

      '.approved-footer-description': {
        fontWeight: 300,
      },

      '.approved-email': {
        fontWeight: 700,
        color: theme.primaryLight,
      },
    },

    '.approved-date-status': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.affiliate-badge': {
        padding: '8px 24px',
        borderRadius: 25,
        fontSize: 14,
        fontWeight: 400,
        color: theme.primaryLight,
        marginRight: 12,
      },

      '.modified-date': {
        fontSize: 14,
        fontWeight: 400,
        color: theme.primaryLight,
      },
    },

    '@media (max-width: 576px)': {
      flexWrap: 'wrap',
    },
  },

  '@media (max-width: 1199px)': {
    padding: '6em 9.25em',
  },

  '@media (max-width: 992px)': {
    padding: '6em',
  },
};
