import { theme } from 'styles';

export const button = (isActive) => ({
  fontWeight: 500,
  color: theme.gray400,
  padding: '0px 8px',
  textTransform: 'uppercase',
  borderBottom: isActive ? `1px solid ${theme.black}` : 'none',

  '&:active': { outline: 'none', borderBottom: `1px solid ${theme.black}` },

  '&:hover': {
    borderBottom: `1px solid ${theme.black}`,
  },
});

export const navBarHeaderContainer = {
  height: 60,
  backgroundColor: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em 11.25em',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,

  '.buttons-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.products': {
      position: 'relative',
      width: '100%',

      '.hovered-content-container': {
        position: 'absolute',
        top: 50,
        transform: 'translate(-33%, 0%)',
        backgroundColor: theme.white,
        border: `1px solid ${theme.secondary}`,
        color: theme.gray500,
        borderRadius: '0.25rem',
        boxShadow: theme.boxShadow,
        height: 680,
        overflow: 'auto',
        padding: 24,

        '.title': {
          width: '100%',
          textAlign: 'center',
          margin: '32px 0',
        },

        '.product-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          '.product': {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 200,
            padding: 24,

            '.image': {
              objectFit: 'none',
            },

            '.product-name': {
              textTransform: 'uppercase',
              fontWeight: 500,
              fontSize: 16,
              textAlign: 'center',
            },

            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.secondary,
            },
          },
        },

        '@media (max-width: 1399px)': {
          maxWidth: 1100,
          margin: '0 32px',
          transform: 'translate(-30%, 0%)',
        },

        '@media (max-width: 1199px)': {
          maxWidth: 1000,
          margin: '0 32px',
          transform: 'translate(-30%, 0%)',
        },

        '@media (max-width: 992px)': {
          maxWidth: 800,
          margin: '0 32px',
          transform: 'translate(-26%, 0%)',
        },

        '@media (max-width: 992px) and (min-width: 767px)': {
          maxWidth: 600,
          margin: '0 32px',
          transform: 'translate(-20%, 0%)',
        },
      },
    },
  },
  '@media (max-width: 1199px)': {
    padding: '1em 6.25em',
  },

  '@media (max-width: 992px)': {
    padding: '1em',
  },
};

export const navBarLeftSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  whiteSpace: 'nowrap',

  '& > svg': {
    height: 36,
    width: 'auto',
  },

  '& > *': {
    margin: '0 2rem 0 0',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
};

export const navBarRightSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  position: 'relative',

  '.languages-container': {
    top: 66,
    left: 0,
  },

  '& > *': {
    marginRight: 16,
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },
};
