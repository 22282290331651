import liposomes from 'assets/images/liposomes-icon.svg';
import melatonin from 'assets/images/melatonin-icon.svg';
import cinnamon from 'assets/images/cinnamon.svg';
import q10 from 'assets/images/q-10-icon.svg';
import retinol from 'assets/images/retinol-icon.svg';
import zeolites from 'assets/images/zeolites-icon.svg';

export const ingredientsData = [
  {
    id: 1,
    icon: liposomes,
    title: 'liposomes',
  },
  {
    id: 2,
    icon: melatonin,
    title: 'melatonin',
  },
  {
    id: 3,
    icon: cinnamon,
    title: 'cinnamon',
  },
  {
    id: 4,
    icon: q10,
    title: 'qTen',
  },
  {
    id: 5,
    icon: liposomes,
    title: 'vitaminC',
  },
  {
    id: 6,
    icon: retinol,
    title: 'retinol',
  },
  {
    id: 7,
    icon: zeolites,
    title: 'zeolites',
  },
  {
    id: 8,
    icon: melatonin,
    title: 'caffeine',
  },
];
