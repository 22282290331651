import { ROUTES } from 'enums';
import facebook from 'assets/images/facebook-icon.svg';
import twitter from 'assets/images/twitter-icon.svg';
import instagram from 'assets/images/instagram-icon.svg';

export const allFromVita = [
  {
    id: 1,
    title: 'skinCare',
    route: ROUTES.SkinCare,
  },
  {
    id: 2,
    title: 'brandStory',
    route: ROUTES.BrandStory,
  },
  {
    id: 3,
    title: 'becomeAnAffiliate',
    route: ROUTES.BecomeAnAffiliate,
  },
];

export const onlineVita = [
  {
    id: 1,
    icon: facebook,
    route: 'https://www.facebook.com',
  },
  {
    id: 2,
    icon: twitter,
    route: 'https://x.com',
  },
  {
    id: 3,
    icon: instagram,
    route: 'https://www.instagram.com',
  },
];
