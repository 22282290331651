import { theme } from 'styles';

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',

  '.shown-results-indicator': {
    color: theme.gray200,
    textAlign: 'center',
    fontSize: 11,
    marginRight: 12,
  },
};

export const paginationButton = (disabled, isActive) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: 32,
    height: 32,
    margin: '0 2px',
    borderRadius: '50%',

    '&:hover': !disabled &&
      !isActive && {
        backgroundColor: theme.gray100,
      },
  },
  isActive && {
    backgroundColor: theme.primary,
  },
  disabled && {
    cursor: 'not-allowed',
    color: theme.gray300,
  },
];

export const pageSizeDropdown = {
  width: 80,
};
