import { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Col, Icon, Image, Input, Row, Tooltip, BecomeAffiliateCart } from 'components';
import { ALL_PRODUCTS_MOCK_DATA, DEFAULT_CURRENCY } from 'enums';
import noPerfumeIcon from 'assets/images/no-perfume-icon.svg';
import naturalPreservativeIcon from 'assets/images/natural-preservative-icon.svg';
import modelImage from 'assets/images/model-image.jpg';
import { ingredientsData } from './config';
import { productDetailsContainer } from './styles';

const ProductDetails = () => {
  const history = useHistory();
  const params = useParams();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const productId = params?.productId ?? history.location?.state?.productId;
  const productInfoDetails = ALL_PRODUCTS_MOCK_DATA?.find((el) => el.id === productId);
  const productInfo = { ...history.location?.state, ...productInfoDetails };
  const inputRef = useRef();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productId]);

  const convertedToLocalCurrencyPrice = (price) =>
    price &&
    printPrice({
      fiat: Number(price),
      fiatOriginCurrency: DEFAULT_CURRENCY.code,
      fiatDesiredCurrency: selectedCurrency?.code,
    });

  return (
    <div css={productDetailsContainer}>
      <Image src={productInfo?.image} className="top-image" />

      <div className="add-card">
        <h3 className="product-title">{getText(productInfo?.title)}</h3>
        <div className="price-icon-quantity-container">
          <p className="product-price">{convertedToLocalCurrencyPrice(productInfo?.price)}</p>
          <Tooltip content={getText('hello')} placement="bottom" noArrow className="info-tooltip">
            <Icon iconName="info" size={16} className="icon" />
          </Tooltip>
          <p className="product-quantity">
            {productInfo?.numberOfQuantity
              ? getText('quantityMl', {
                  quantity: productInfo?.quantity,
                  numberOfQuantity: productInfo?.numberOfQuantity,
                })
              : getText('ml', { quantity: productInfo?.quantity })}
          </p>
        </div>
        <div className="action-container">
          <Input
            ref={inputRef}
            type="number"
            className="input"
            value={1}
            onChange={() => setQuantity(inputRef?.current?.value)}
          />
          <Button small type="default" className="button-default" onClick={() => {}}>
            {getText('addToCart')}
          </Button>
        </div>
      </div>

      <div className="product-container">
        <Row horizontalGap={32}>
          <Col xl={5} lg={5} md={12} xs={12} className="column">
            <Image src={productInfo?.image} className="product-image" />
          </Col>
          <Col xl={7} lg={7} md={12} xs={12} className="column-info">
            <div className="product-info-container">
              <p className="introducing-style">{getText('introducing')}</p>
              <h3 className="product-title">{getText(productInfo?.title)}</h3>
              <p className="product-description">{getText('introducingProductDescription')}</p>
              <div className="icons-container">
                <Image src={noPerfumeIcon} width={40} height={40} className="icon-image" />
                <span className="natural">{getText('noPerfume')}</span>
                <Image src={naturalPreservativeIcon} width={38} height={40} className="icon-image" />
                <span className="natural">{getText('naturalPreservative')}</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="product-container">
        <Row horizontalGap={32} className="reversible-container">
          <Col xl={7} lg={7} md={12} xs={12} className="ingredients-column-info">
            <div className="product-info-container">
              <h3 className="product-title">{getText('keyIngredients')}</h3>
              <p className="product-description">{getText('keyIngredientsDescription')}</p>
              <Button small type="default" className="button-default" onClick={() => {}}>
                {getText('buyNow')}
              </Button>
            </div>
          </Col>
          <Col xl={5} lg={5} md={12} xs={12} className="column">
            <Image src={productInfo?.image} className="product-image" />
          </Col>
        </Row>
      </div>

      <Image src={modelImage} height={500} className="model-image" />

      <div className="more-information-container">
        <h3 className="more-info-title">{getText('moreInformation')}</h3>

        <Row gap={16}>
          {ingredientsData.map((el, ind) => (
            <Col xl={3} lg={4} md={6} xs={12} key={ind} className="ingredients-cart">
              <Image src={el.icon} className="more-image" />
              <p className="more-info">
                <span className="more-title">{getText(el.title)}</span>
                {getText(el.description)}
              </p>
            </Col>
          ))}
        </Row>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default ProductDetails;
