import { theme } from 'styles';

export const affiliateContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5em 0',
  color: theme.white,
  backgroundColor: theme.black,

  '.affiliate-title': {
    fontWeight: 400,
    fontSize: 32,
    marginBottom: 24,
  },

  '.affiliate-button': {
    textTransform: 'uppercase',
    marginBottom: 36,
    fontSize: 16,
    fontWeight: 400,
    padding: '1em 5em',

    '&:active': { outline: 'none' },

    '@media (max-width: 767px)': {
      width: '90%',
    },
  },
};
