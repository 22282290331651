import { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { flatten } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Languages, MenuItem, SideNav, NavBar, useWindowSize } from 'components';
import { useUserStore } from 'stores';
import { ROUTES } from 'enums';
import { MyAffiliates } from './private';
import {
  BecomeAnAffiliate,
  BecomeAnAffiliateForm,
  BrandStory,
  HomePage,
  Ingredients,
  Products,
  ProductDetails,
  SkinCare,
} from 'screens/public';
import { mainContainer } from 'styles/globalCss';
import { PublicRoutes } from './public/PublicRoutes';
import { Footer, NotFound } from './public';

export const Routes = () => {
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const [isMobile, setIsMobile] = useState(windowWidth <= 767);

  useEffect(() => {
    setIsMobile(windowWidth.width <= 767);
  }, [windowWidth.width]);

  return isMobile ? (
    <>
      <SideNav
        homeRoute={ROUTES.Home}
        isMobile={isMobile}
        routes={flatten(Object.values(PublicRoutes).map((el) => el.internal))}
        notFoundComponent={NotFound}>
        <MenuItem
          exact
          url={ROUTES.Products}
          label={getText('products')}
          subMenus={PublicRoutes.products.subMenus}
          component={() => <Products isMobile={isMobile} />}
        />
        <MenuItem
          url={ROUTES.SkinCare}
          label={getText('skinCare')}
          component={() => <SkinCare isMobile={isMobile} />}
        />
        <MenuItem url={ROUTES.BrandStory} label={getText('brandStory')} component={BrandStory} />
        {isAuthenticated ? (
          <MenuItem url={ROUTES.MyAffiliates} label={getText('myAffiliates')} component={MyAffiliates} />
        ) : (
          <MenuItem url={ROUTES.BecomeAnAffiliate} label={getText('becomeAnAffiliate')} component={BecomeAnAffiliate} />
        )}
        <MenuItem externalComponent={<Languages />} />
      </SideNav>
      <Footer />
    </>
  ) : (
    <main css={mainContainer}>
      <NavBar />
      <Switch>
        {isAuthenticated && <Route exact path={ROUTES.MyAffiliates} component={MyAffiliates} />}
        <Route exact path={ROUTES.BecomeAnAffiliate} component={BecomeAnAffiliate} />
        <Route exact path={ROUTES.BecomeAnAffiliateApply} component={BecomeAnAffiliateForm} />
        <Route exact path={ROUTES.BrandStory} component={BrandStory} />
        <Route exact path={ROUTES.Home} component={HomePage} />
        <Route exact path={ROUTES.Ingredients} component={Ingredients} />
        <Route exact path={ROUTES.Products} component={() => <Products isMobile={isMobile} />} />
        <Route exact path={ROUTES.ProductDetails} component={() => <ProductDetails isMobile={isMobile} />} />
        <Route exact path={ROUTES.SkinCare} component={() => <SkinCare isMobile={isMobile} />} />
        <Redirect exact from="/" to={ROUTES.Home} />
      </Switch>
      <Footer />
    </main>
  );
};
