import { theme } from 'styles';

export const homePageContainer = (isMobile) => ({
  width: '100vw',

  '.header-image': {
    height: 500,
  },

  '.upper-text': {
    textTransform: 'uppercase',
    fontWeight: 300,
    fontSize: 20,
    marginBottom: 8,
  },

  '.title': {
    fontWeight: 400,
    fontSize: 48,
    marginBottom: 24,
    color: theme.black,
  },

  '.secondary-button': {
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    color: theme.gray500,
    border: `1px solid ${theme.grayLight}`,
    fontSize: 16,
    fontWeight: 400,
    width: 'fit-content',
  },

  '.crystal-image-container': {
    position: 'relative',
    zIndex: 10,
    height: 500,
    display: 'flex',
    flexWrap: 'wrap',

    '.text-over-crystal-container': {
      position: 'absolute',
      width: '100%',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5em 2em',

      '.title-company': {
        fontWeight: 300,
        fontSize: 24,
        color: theme.black80,
        textTransform: 'uppercase',
        marginBottom: 16,
      },

      '.title-beauty': {
        fontWeight: 400,
        fontSize: 54,
        color: theme.black,
        marginBottom: 16,
        textAlign: 'center',
      },

      '.description': {
        fontWeight: 300,
        fontSize: 22,
        maxWidth: 853,
        textAlign: 'center',
        marginBottom: 36,
      },
    },

    '@media (max-width: 767px)': {
      height: 600,
    },
  },

  '.from-nature-container': {
    backgroundColor: theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6.8em 11.25em',

    '.product-slider': {
      borderBottom: isMobile ? 'none' : `1px solid ${theme.black10}`,
    },

    '@media (max-width: 767px)': {
      padding: '6.8em 2em',
    },
  },

  '.about-container': {
    '.about-image': {
      width: '100%',
    },

    '.about-info': {
      backgroundColor: theme.secondary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 24,
    },

    '.description-about': {
      fontSize: 20,
      fontWeight: 300,
      color: theme.gray300,
      maxWidth: 530,
      marginBottom: 32,
    },

    '.about-secondary-button': {
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      color: theme.gray500,
      border: `1px solid ${theme.grayLight}`,
      fontSize: 16,
      fontWeight: 400,
      width: 'fit-content',

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  },

  '.routine-steps-container': {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 48,
    marginBottom: 48,
  },

  '.secrets': {
    backgroundColor: theme.secondaryLighter,

    '.secrets-container': {
      padding: '3em 11.25em',

      '.title-secrets': {
        fontWeight: 400,
        fontSize: 48,
        marginBottom: 24,
        color: theme.black,
        maxWidth: 300,
      },

      '.secrets-description-container': {
        display: 'flex',
        alignItems: 'center',

        '.description-secrets': {
          fontSize: 20,
          fontWeight: 300,
          color: theme.gray300,
          maxWidth: 580,
          marginBottom: 32,
        },
      },

      '@media (max-width: 767px)': {
        padding: '3em',
      },
    },

    '.secrets-button-container': {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 36,
      padding: '0 0  3em 3em',

      '@media (max-width: 767px)': {
        justifyContent: 'flex-start',
      },
    },
  },
});
