import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Image } from 'components';
import { ROUTES } from 'enums';
import cleansingGel from 'assets/images/cleaning-gel.jpg';
import fillerSerum from 'assets/images/filler-serum.jpg';
import eyeCream from 'assets/images/eye.jpg';
import { discoverBeautyContainer } from './styles';

const DiscoverBeauty = () => (
  <div css={discoverBeautyContainer}>
    <div className="discover-beauty-first-col">
      <h3 className="discover-beauty-title">{startCase(getText('discoverTimelessBeauty'))}</h3>
      <p className="discover-beauty-subtitle">{getText('unveilSecretOfLiposomesAndZeolites')}</p>

      <Button type="secondary" className="action-button" linkTo={ROUTES.Products}>
        {getText('exploreProducts')}
      </Button>

      <Image src={cleansingGel} />
    </div>

    <div className="discover-beauty-second-col">
      <Image src={fillerSerum} className="discover-beauty-second-image" />
      <Image src={eyeCream} className="discover-beauty-third-image" />
    </div>
  </div>
);

export default DiscoverBeauty;
