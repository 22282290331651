import { theme } from 'styles';

export const affiliateFromContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',

  '.apply-button': {
    width: '100%',
    textTransform: 'uppercase',
  },

  '.affiliate-form-header': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    backgroundColor: theme.primaryLight,
    height: 400,

    '.affiliate-form-header-info': {
      color: theme.white,
      position: 'absolute',
      top: 100,
      left: '25%',
      right: '25%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.header-title': {
        fontSize: 40,
        fontWeight: 400,
        textAlign: 'center',
        marginBottom: 12,
        maxWidth: 450,

        '@media (max-width: 992px)': {
          width: '100%',
        },

        '@media (max-width: 576px)': {
          textAlign: 'start',
        },
      },

      '.header-subtitle': {
        fontSize: 20,
        fontWeight: 300,
        maxWidth: 400,
        textAlign: 'center',
        marginBottom: 40,

        '@media (max-width: 992px)': {
          marginBottom: 12,
        },

        '@media (max-width: 767px)': {
          textAlign: 'left',
        },
      },

      '@media (max-width: 767px)': {
        left: '15%',
        top: 50,
      },

      '@media (max-width: 576px)': {
        left: 0,
        right: 0,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '1em 2em',
      },
    },
  },

  '.form-container': {
    maxWidth: 500,
    backgroundColor: theme.white,
    padding: '60px 32px',
    border: `1px solid ${theme.black}`,
    borderRadius: 8,
    marginBottom: 40,
    marginTop: 270,
    zIndex: 10,

    '.input-label': {
      width: 150,
    },

    '.phone-input': {
      '.phone-dropdown': {
        '.input-container': {
          '.phone-dropdown': {
            '.select-dropdown': {
              backgroundColor: theme.white,
              border: 'none',
              borderBottom: `1px solid ${theme.black24}`,
            },
          },
        },
      },
    },

    '.dropdown': {
      '& > .select-dropdown': {
        border: 'none',
        borderBottom: `1px solid ${theme.black24}`,
        backgroundColor: theme.white,
      },
    },

    '.kyc-title': {
      fontSize: 20,
      textTransform: 'uppercase',
      color: theme.gray200,
      margin: '32px 0 16px',
    },

    '.upload-id-proof-container': {
      display: 'flex',
      flexDirection: 'row',

      '.upload-id-doc': {
        marginRight: 16,

        '@media (max-width: 576px)': {
          marginRight: 0,
        },
      },

      '@media (max-width: 576px)': {
        flexWrap: 'wrap',
      },
    },

    '.address-proof': {
      '.input-container': {
        '.address-proof': {
          '.label-container': {
            '.button': {
              '.upload-label': {
                maxWidth: 210,
              },
            },
          },
        },
      },
    },

    '.check-button': {
      textTransform: 'uppercase',
      fontSize: 20,
      marginBottom: 24,
    },

    '.affiliate-form-info': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '.form-info-title': {
        paddingTop: 24,
        fontSize: 18,
        fontWeight: 300,
        textAlign: 'center',
        marginBottom: 12,
        color: theme.black,
      },
      '.form-info-description-container': {
        fontSize: 14,

        '.form-info-description': {
          color: theme.black56,
        },

        '.touch-text': {
          margin: '0 4px',
        },
      },
    },

    '@media (max-width: 767px)': {
      marginTop: 670,
    },

    '@media (max-width: 576px)': {
      marginTop: 670,
      borderRadius: 0,
    },
  },

  '.success-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    backgroundColor: theme.white,
    padding: 60,
    border: `1px solid ${theme.black}`,
    borderRadius: 8,
    margin: '40px 24px',

    '& > *': {
      textAlign: 'center',
      marginBottom: 12,
    },

    '.success-icon': {
      width: 129,
      height: 128,

      '@media (max-width: 576px)': {
        width: 81,
        height: 80,
      },
    },

    '.success-title': {
      fontSize: 48,
      fontWeight: 400,
      color: theme.black,

      '@media (max-width: 576px)': {
        fontSize: 40,
      },
    },

    '.success-subtitle': {
      fontSize: 20,
      fontWeight: 300,
      marginBottom: 32,
      color: theme.primaryLight,
    },

    '.not-receive-email': {
      fontSize: 24,
      fontWeight: 400,
      color: theme.black,
    },

    '.email-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      marginBottom: 24,
      color: theme.black,
    },

    '@media (max-width: 576px)': {
      padding: 40,
    },
  },
};
