import { theme } from 'styles';
export const phoneInputContainer = (hasValidation) => ({
  display: 'flex',
  width: '100%',
  marginBottom: hasValidation ? 6 : 0,

  '.phone-input': {
    height: 52,
    backgroundColor: theme.white,
  },
});

export const phoneInputCountrySelect = {
  width: 100,
};

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};
