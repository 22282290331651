import { useRef, useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { isBoolean, isArray, pick, isEmpty, isFunction, isNil } from 'lodash-es';
import qs from 'query-string';
import { Route, Redirect, useHistory, Switch } from 'react-router-dom';
import { useDeepEffect } from '../hooks';
import { concatStrings } from '../utils';
import { fadeInAnimation } from '../../../styles';
import { tabs, tabsListContainer } from './styles';

const Tabs = (props) => {
  const {
    children: initChildren,
    startingRoute,
    routeMatch,
    listClass,
    contentClass,
    sharedQueryParams,
    pills,
    vertical,
    className,
    onChange,
    noAnimation,
  } = props;

  const history = useHistory();
  const { location } = history;
  const children = Children.toArray(initChildren ?? []).filter(Boolean);
  const hasRoutes = children.every((el) => el.props?.url);
  const [activeTab, setActiveTab] = useState(children.length && children[0]?.props?.label);
  const contentContainerRef = useRef();

  useDeepEffect(() => {
    setActiveTab(children.length && children[0]?.props?.label);
  }, [children]);

  if (!children.length) return null;

  const parsedQueryParams = qs.parse(location.search);
  const tabQueryParams = isBoolean(sharedQueryParams)
    ? parsedQueryParams
    : isArray(sharedQueryParams)
    ? pick(parsedQueryParams, sharedQueryParams)
    : {};
  const stringifiedQueryParams = isEmpty(tabQueryParams) ? '' : `?${qs.stringify(tabQueryParams)}`;
  const defaultUrl = `${startingRoute}${children[0]?.props?.url}${stringifiedQueryParams}`;

  const handleTabChange = (tab, onClickProp) => {
    isFunction(onChange) && onChange(tab);
    isFunction(onClickProp) && onClickProp(tab);

    setActiveTab(tab);
    const contentNode = contentContainerRef.current;
    if (!noAnimation && contentNode.getAttribute('data-active-tab') !== tab) {
      contentNode.setAttribute('data-active-tab', tab);

      contentNode.style.animation = 'none';
      setTimeout(() => {
        contentNode.style.animation = '';
      });
    }
  };

  const renderRouteChilds = children.map((child) => {
    const { label, url, component } = child.props;
    return <Route exact key={label} path={`${routeMatch ?? startingRoute}${url}`} render={component} />;
  });

  const activeTabChild = children.find((el) => el.props.label === activeTab);
  const ActiveComponent = activeTabChild?.props.component;

  return (
    <div
      css={tabs(props, isNil(activeTabChild?.props?.withPadding) || activeTabChild?.props?.withPadding)}
      {...(className && { className })}>
      <div className={concatStrings('tabs-list-container', listClass)} role="tablist" css={tabsListContainer(vertical)}>
        {children.map((child) =>
          cloneElement(child, {
            key: child.props.label,
            active: activeTab === child.props.label,
            onClick: (label) => handleTabChange(label, child.props.onClick),
            hasRoutes,
            url: `${startingRoute}${child.props.url}${stringifiedQueryParams}`,
            pills,
            vertical,
          }),
        )}
      </div>
      <div
        ref={contentContainerRef}
        className={concatStrings('tabs-content', contentClass)}
        css={[!noAnimation && fadeInAnimation()]}>
        {hasRoutes ? (
          <Switch>
            {renderRouteChilds}
            <Redirect from={startingRoute} to={defaultUrl} />
          </Switch>
        ) : (
          ActiveComponent && <ActiveComponent />
        )}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  startingRoute: PropTypes.string,
  routeMatch: PropTypes.string,
  children: PropTypes.instanceOf(Array),
  listClass: PropTypes.string,
  contentClass: PropTypes.string,
  sharedQueryParams: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  pills: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  noAnimation: PropTypes.bool,
};

export default Tabs;
