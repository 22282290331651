import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { softLogout } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image } from 'components';
import { ROUTES } from 'enums';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { container, profile, profileNavStyles } from './styles';

const User = () => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const history = useHistory();
  const [openContainer, setOpenContainer] = useState(false);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={profileNavStyles}>
        <Image
          src={user?.userInfo?.pictureUrl ?? user?.userInfo?.picThumbnailUrl ?? avatarLogo}
          className="avatar-image"
        />
      </div>

      {openContainer && (
        <div css={container}>
          <div role="button" tabIndex={0} css={profile}>
            <div className="item" role="button" tabIndex={0} onClick={() => history.push(ROUTES.MyAffiliates)}>
              <Icon material iconName="account_circle" />
              <h5>{getText('myAffiliates')}</h5>
            </div>
          </div>

          <Button small type="default" onClick={softLogout} className="button">
            <Icon iconName="logout" className="icon" onClick={() => history.push(ROUTES.Home)} />
            {getText('logOut')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default User;
