import { keyframes } from '@emotion/react';
import { theme } from 'styles';

const animate = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(-100%);
}
`;

export const ingredientsContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: 24,
  width: '100vw',
  overflow: 'hidden',

  '.ingredients-badge-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '0 16px',
    animation: `${animate} 40s infinite linear`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',

    '.badge': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      width: 'fit-content',
      minWidth: 140,
      marginRight: 24,

      '.ingredient-badge': {
        backgroundColor: 'transparent',
        color: theme.black,
        marginRight: 16,
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        border: 'none',

        '&:last-child': {
          marginRight: 0,
        },

        '.batch-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          '.badge-image': {
            width: 30,
            height: 30,
            marginRight: 12,
          },

          '.badge-text': {
            fontWeight: 400,
          },
        },
      },
    },

    '@media (max-width: 767px)': {
      overflow: 'unset',
      whiteSpace: 'normal',
      width: 'auto',
    },
  },
};
