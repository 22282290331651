import { useEffect } from 'react';
import { getText } from '@veraio/strank';
import {
  Button,
  Col,
  Image,
  Row,
  BecomeAffiliateCart,
  CarouselProducts,
  IngredientsSlider,
  ProductsSlider,
} from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTES, ROUTINE_PRODUCTS_DATA } from 'enums';
import homeHeader from 'assets/images/home-header.jpg';
import { ReactComponent as SilverCrystalImage } from 'assets/images/silver-crystal.svg';
import { ReactComponent as About } from 'assets/images/about.svg';
import { ingredientsData } from './config';
import { homePageContainer } from './styles';

const HomePage = ({ isMobile }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={homePageContainer(isMobile)}>
      <Image src={homeHeader} className="header-image" />

      <div className="crystal-image-container">
        <SilverCrystalImage />
        <div className="text-over-crystal-container">
          <p className="title-company">{getText('oneVita')}</p>
          <h4 className="title-beauty">{getText('secretToTimelessBeauty')}</h4>
          <p className="description">{getText('homeProductsDescription')}</p>
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Products}>
            {getText('exploreProducts')}
          </Button>
        </div>
      </div>

      <div className="from-nature-container">
        <p className="upper-text">{getText('elevateYourGlow')}</p>
        <h4 className="title">{getText('healthAndBeautyFromNature')}</h4>
        <ProductsSlider products={ALL_PRODUCTS_MOCK_DATA} className="product-slider" isMobile={isMobile} />
      </div>

      <Row className="about-container">
        <Col xl={6} lg={6} md={6} xs={12}>
          <About className="about-image" />
        </Col>
        <Col xl={6} xs={12} className="about-info">
          <p className="upper-text">{getText('aboutOneVita')}</p>
          <h4 className="title">{getText('smallButMighty')}</h4>
          <p className="description-about">{getText('aboutVitaDescriptionHomePage')}</p>
          <Button type="secondary" className="about-secondary-button" linkTo={ROUTES.BrandStory}>
            {getText('ourStory')}
          </Button>
        </Col>
      </Row>

      <div className="routine-steps-container">
        <p className="upper-text">{getText('fiveStepSystem')}</p>
        <h4 className="title">{getText('forYouthfulSkin')}</h4>
        <CarouselProducts products={ROUTINE_PRODUCTS_DATA} isMobile={isMobile} />
      </div>

      <div className="secrets">
        <Row className="secrets-container" horizontalGap={24}>
          <Col xl={4} lg={4} md={12} xs={12}>
            <h4 className="title-secrets">{getText('theSecretOfOneVita')}</h4>
          </Col>
          <Col xl={8} lg={8} md={12} xs={12} className="secrets-description-container">
            <p className="description-secrets">{getText('revolutionaryMicroSizedMolecules')}</p>
          </Col>
        </Row>
        <IngredientsSlider ingredients={ingredientsData} />
        <div className="secrets-button-container">
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Ingredients}>
            {getText('learnMore')}
          </Button>
        </div>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default HomePage;
