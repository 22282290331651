import { useEffect } from 'react';
import { getText } from '@veraio/strank';
import { Button, Col, ColorBatch, Image, Row, BecomeAffiliateCart } from 'components';
import { ROUTES } from 'enums';
import brandStoryHeaderImage from 'assets/images/brand-story-header.jpg';
import blondeWomanCreamLogoImage from 'assets/images/women-cream-logo.jpg';
import productsBrandStoryImage from 'assets/images/products-brand-story-image.jpg';
import womenFormulatedBrandStoryImage from 'assets/images/view-women-formulated-brand-story.jpg';
import { brandIngredients } from './config';
import { brandStoryContainer } from './styles';

const BrandStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={brandStoryContainer}>
      <div className="header-container">
        <h3 className="title">{getText('smallButMightyUnlockPower')}</h3>
        <p className="subtitle">{getText('fromCoreOfTheCellAndTheDepths')}</p>
      </div>
      <Image src={brandStoryHeaderImage} className="brand-image" />

      <div className="content-container">
        <Row gap={24} className="small-container">
          <Col xl={6} lg={6} md={12} xs={12} className="text-content">
            <h4 className="content-title">{getText('smallButMighty')}</h4>
            <p className="content-subtitle">{getText('withPrecisionOfScienceAndCareful')}</p>
          </Col>
          <Col xl={6} lg={6} md={12} xs={12} className="image-content">
            <Image src={blondeWomanCreamLogoImage} className="women-image" />
          </Col>
        </Row>

        <Row gap={24} className="reversible-container">
          <Col xl={6} lg={6} md={12} xs={12}>
            <Image src={productsBrandStoryImage} className="product-image" />
          </Col>
          <Col xl={6} lg={6} md={12} xs={12} className="text-content">
            <h4 className="content-title">{getText('products')}</h4>
            <p className="content-subtitle">{getText('productsProvideCompleteDailySkinCare')}</p>
            <Button type="default" className="button-default" onClick={() => {}}>
              {getText('exploreProducts')}
            </Button>
          </Col>
        </Row>
      </div>

      <div className="ingredients-container">
        <Row gap={24}>
          <Col xl={6} lg={6} md={12} xs={12} className="text-content-ingredients">
            <h4 className="ingredients-content-title">{getText('formulatedWithHighTech')}</h4>
            <div className="batch-container">
              {brandIngredients.map((el, ind) => (
                <ColorBatch withoutStartCase type="default" key={ind} className="batch-styles">
                  {getText(el.text)}
                </ColorBatch>
              ))}
            </div>
            <Button type="default" className="button-default-ingredients" linkTo={ROUTES.Ingredients}>
              {getText('readMoreAboutTheIngredients')}
            </Button>
          </Col>
          <Col xl={6} lg={6} md={12} xs={12} className="image-content">
            <Image src={womenFormulatedBrandStoryImage} className="image-formulated" />
          </Col>
        </Row>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default BrandStory;
