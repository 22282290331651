import cleansingGel from 'assets/images/cleaning-gel.jpg';
import fillerSerum from 'assets/images/filler-serum.jpg';
import eyeCream from 'assets/images/eye.jpg';
import nightCream from 'assets/images/night-cream.jpg';
import cleansingGelNav from 'assets/images/cleaning-gel.svg';
import fillerSerumNav from 'assets/images/filler-serum.svg';
import eyeCreamNav from 'assets/images/eye-cream.svg';
import dayCreamNav from 'assets/images/day-cream.svg';
import nightCreamNav from 'assets/images/night-cream.svg';
import routineCleaningGel from 'assets/images/routine-cleaning-gel.svg';
import routineFillerSerum from 'assets/images/routine-filler-serum.svg';
import routineEyeCream from 'assets/images/routine-eye-cream.svg';
import routineDayCream from 'assets/images/routine-day-cream.svg';
import routineNightCream from 'assets/images/routine-night-cream.svg';

export const ALL_PRODUCTS_MOCK_DATA = [
  {
    id: 1,
    image: cleansingGel,
    imageNav: cleansingGelNav,
    isRightImage: false,
    title: 'liposomalCleansingGel',
    footerTitle: 'cleaningGel',
    navTitle: 'cleansingGel',
    description: 'liposomalCleansingGelDescription',
    price: 14.88,
    quantity: 100,
  },
  {
    id: 2,
    image: fillerSerum,
    imageNav: fillerSerumNav,
    isRightImage: true,
    title: 'liposomalFillerSerum',
    footerTitle: 'fillerSerum',
    navTitle: 'fillerSerum',
    description: 'liposomalFillerSerumDescription',
    price: 45.99,
    quantity: 40,
  },
  {
    id: 3,
    image: eyeCream,
    imageNav: eyeCreamNav,
    isRightImage: false,
    title: 'liposomalEyeCream',
    footerTitle: 'eyeCream',
    navTitle: 'eyeContouringCream',
    description: 'liposomalEyeCreamDescription',
    price: 12.49,
    quantity: 25,
  },
  {
    id: 4,
    image: nightCream,
    imageNav: dayCreamNav,
    isRightImage: true,
    title: 'liposomalDayCream',
    footerTitle: 'dayCream',
    navTitle: 'dayCreamAndMoisturizer',
    description: 'liposomalDayCreamDescription',
    price: 14.99,
    quantity: 50,
  },
  {
    id: 5,
    image: nightCream,
    imageNav: nightCreamNav,
    isRightImage: false,
    title: 'liposomalNightCream',
    footerTitle: 'nightCream',
    navTitle: 'nightCream',
    description: 'liposomalNightCreamDescription',
    price: 17.99,
    quantity: 50,
  },
  {
    id: 6,
    image: nightCream,
    imageNav: nightCreamNav,
    isRightImage: true,
    title: 'liposomalCalmingAndHydratingMask',
    footerTitle: 'calmingAndHydratingMask',
    navTitle: 'calmingAndHydratingMask',
    description: 'liposomalCalmingAndHydratingMaskDescription',
    price: 99.99,
    quantity: 8,
    numberOfQuantity: 5,
  },
  {
    id: 7,
    image: nightCream,
    imageNav: nightCreamNav,
    isRightImage: false,
    title: 'liposomalMetabolicActivatingMask',
    footerTitle: 'metabolicMask',
    navTitle: 'metabolicMask',
    description: 'liposomalMetabolicActivatingMaskDescription',
    price: 14.88,
    quantity: 8,
    numberOfQuantity: 5,
  },
];

export const ROUTINE_PRODUCTS_DATA = [
  {
    id: 1,
    image: routineCleaningGel,
    title: 'purifyAndRefresh',
    description: 'effortlesslyLiftAwayImpuritiesAndMakeup',
    buttonText: 'liposomalCleansingGel',
    step: '01',
    isRightImage: true,
  },
  {
    id: 2,
    image: routineFillerSerum,
    title: 'deepHydrationAndPlumping',
    description: 'powerfulElasticityBoostingIngredients',
    buttonText: 'liposomalFillerSerum',
    step: '02',
    isRightImage: false,
  },
  {
    id: 3,
    image: routineEyeCream,
    title: 'eyeRevival',
    description: 'immediateTighteningAndLiftingEffect',
    buttonText: 'liposomalEyeCream',
    step: '03',
    isRightImage: true,
  },
  {
    id: 4,
    image: routineDayCream,
    title: 'daytimeDefense',
    description: 'hydrateEnergizeAndProtectYourSkin',
    buttonText: 'liposomalDayCream',
    step: '04',
    isRightImage: false,
  },
  {
    id: 5,
    image: routineNightCream,
    title: 'overnightRenewal',
    description: 'powerfulNightAntiAgingComboForSkin',
    buttonText: 'liposomalNightCream',
    step: '05',
    isRightImage: true,
  },
];
