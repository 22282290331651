import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components';
import { languageIconContainer, contentContainer, item } from './styles';

const Languages = ({ className }) => {
  const { getText, allEnvLanguages, changeLanguage, language } = useTranslations();
  const [openContainer, setOpenContainer] = useState(false);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={languageIconContainer}>
        <Icon material size="3rem" iconName="language" className="icon" color="gray500" />
        <span>{language?.code?.toLocaleUpperCase()}</span>
      </div>

      {openContainer && (
        <div css={contentContainer} {...(className && { className })}>
          {allEnvLanguages?.map((el, ind) => {
            return (
              <div
                role="button"
                tabIndex={0}
                key={ind}
                onClick={() => changeLanguage(el.code)}
                css={item(el.name === language.name)}>
                <p className="name">{getText(`${el.name}`)}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Languages;
