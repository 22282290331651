import { theme } from 'styles';
export const productDetailsContainer = {
  position: 'relative',

  '.top-image': {
    height: 480,
    objectFit: 'fill',
    zIndex: 1,
  },

  '.product-title': {
    textTransform: 'uppercase',
    fontSize: 20,
    color: theme.black,
    fontWeight: 400,
    marginBottom: 12,
  },

  '.button-default': {
    backgroundColor: theme.black,
    color: theme.white,
    border: `1px solid ${theme.grayLight}`,
    padding: '0.5em 3em',
    height: '4.9rem',
    textTransform: 'uppercase',

    '&:active': { outline: 'none' },
  },

  '.add-card': {
    position: 'absolute',
    maxWidth: 400,
    top: 150,
    right: 100,
    zIndex: 10,
    padding: '3em',
    backgroundColor: theme.white,
    boxShadow: theme.boxShadow,

    '.price-icon-quantity-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 24,

      '.product-price': {
        fontSize: 22,
        marginRight: 8,
        color: theme.black,
        fontWeight: 400,
      },

      '.info-tooltip': {
        marginRight: 8,

        '.icon': {
          color: theme.black,
        },
      },

      '.product-quantity': {
        fontSize: 22,
        color: theme.black56,
        fontWeight: 400,
      },
    },

    '.action-container': {
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',

      '.input': {
        width: 100,
        marginRight: 8,
      },
    },

    '@media (max-width: 992px)': {
      position: 'relative',
      maxWidth: '100%',
      top: 0,
      right: 0,
      margin: '3em',
    },
  },

  '.product-info-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '.introducing-style': {
      fontWeight: 400,
      fontSize: 18,
      marginBottom: 8,
      color: theme.black,
    },

    '.product-description': {
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray800,
      marginBottom: 20,
      lineHeight: 1.7,
    },

    '.icons-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',

      '.icon-image': {
        marginRight: 8,
      },

      '.natural': {
        marginRight: 16,
        fontSize: 14,
        fontWeight: 400,
      },
    },
  },

  '.product-container': {
    padding: '3em 11.25em',

    '.column': {
      position: 'relative',
      minHeight: 400,
      display: 'flex',
      flex: 1,
      backgroundColor: theme.gray300,
      // TO-DO make backgroundColor to be secondary when change product images

      '.product-image': {
        position: 'relative',
        objectFit: 'fill',
        top: 0,
        right: -60,
        maxHeight: '90%',
      },
    },

    '.column-info': {
      display: 'flex',
      marginLeft: 32,

      '@media (max-width: 992px)': {
        padding: 0,
        paddingTop: 24,
        marginLeft: 0,
      },
    },

    '.reversible-container': {
      '.ingredients-column-info': {
        display: 'flex',
        flexDirection: 'column',

        '@media (max-width: 992px)': {
          padding: 0,
          paddingTop: 24,
          flexDirection: 'column-reverse',
        },
      },

      '@media (max-width: 992px)': {
        flexDirection: 'column-reverse',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em 5em',
    },
  },

  '.model-image': {
    height: 550,
  },

  '.more-information-container': {
    padding: '3em 11.25em',
    backgroundColor: theme.secondary,

    '.more-info-title': {
      fontWeight: 400,
      fontSize: 48,
      color: theme.black,
      marginBottom: 40,
    },

    '.ingredients-cart': {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',

      '.more-image': {
        width: 40,
        height: 40,
        marginBottom: 16,
        objectFit: 'contain',
      },

      '.more-info': {
        fontSize: 16,
        fontWeight: 300,
        color: theme.gray300,
        lineHeight: 1.4,

        '.more-title': {
          fontSize: 16,
          fontWeight: 700,
          color: theme.black,
          textTransform: 'uppercase',
          marginRight: 4,
          lineHeight: 1.4,
        },
      },
    },
  },
};
