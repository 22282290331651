export const brandIngredients = [
  { id: 1, text: 'liposomes' },
  { id: 2, text: 'ceramides' },
  { id: 3, text: 'peptides' },
  { id: 4, text: 'microEncapsulates' },
  { id: 5, text: 'preProPostBiotics' },
  { id: 6, text: 'antioxidants' },
  { id: 7, text: 'lipids' },
  { id: 8, text: 'collagens' },
  { id: 9, text: 'botanicalAntiox' },
  { id: 10, text: 'vitamins' },
  { id: 11, text: 'aminoAcids' },
  { id: 12, text: 'retinol' },
  { id: 13, text: 'vitamins' },
  { id: 14, text: 'reefSafeUvAbsorbers' },
  { id: 15, text: 'naturalOilsAndButters' },
  { id: 16, text: 'tripleSizeHyaluronicAcid' },
];
