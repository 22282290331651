import { commonStyles, theme } from 'styles';

export const container = (props) => [
  {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    marginLeft: 0,
    padding: 0,
  },
  ...commonStyles(props),
];

export const text = {
  fontSize: 14,
  fontWeight: 600,
  color: theme.gray200,
};

export const linkItem = (isActive) => ({
  fontSize: 14,
  fontWeight: 600,
  color: isActive ? theme.info : theme.gray200,
});

export const separator = {
  margin: '0px 8px',
  fontSize: 14,
  fontWeight: 600,
  color: theme.gray200,
};
