import { isArray } from 'lodash-es';
import { getText } from '@veraio/strank';
import { showInfo } from 'components';

export const copyToClipboard = (text, message) => {
  const textArea = document.createElement('textarea');
  textArea.style.opacity = '0';
  textArea.value = isArray(text) ? text.join('\n') : text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  showInfo(getText(message));
};
