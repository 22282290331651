import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useLocations, changeUserCurrency } from '@oneecosystem/dealshaker-core';
import { Button, Col, Dropdown, Image, Row } from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { allFromVita, onlineVita } from './config';
import { footerContainer } from './styles';

const Footer = (props) => {
  const { isMobile, className } = props;
  const { getText, changeLanguage } = useTranslations();
  const geoLocation = useLocations();
  const getYear = new Date().getFullYear();
  const history = useHistory();

  // TO-DO make select country to work for every cases currency, language and prices

  const handleChangeCountry = (val) => {
    changeUserCurrency(val);
    changeLanguage(val);
  };

  return (
    <div css={footerContainer(isMobile)} {...(className && { className })}>
      <div className="products-footer-container">
        <Row>
          <Col xl={4} lg={4} md={6} xs={12}>
            <p className="footer-label">{getText('products')}</p>
            <Row>
              {ALL_PRODUCTS_MOCK_DATA?.map((el, ind) => (
                <Col xl={6} lg={12} md={12} xs={12} key={ind}>
                  <Button
                    type="link"
                    onClick={() => history.push({ pathname: `/products/${el.id}`, state: el })}
                    className="footer-link-button">
                    {getText(el.footerTitle)}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xl={2} lg={2} md={2} xs={12} />
          <Col xl={3} lg={3} md={4} xs={12}>
            <p className="footer-label">{getText('fromOneVita')}</p>
            <Row>
              {allFromVita?.map((el, ind) => (
                <Col xl={12} lg={12} md={12} xs={12} key={ind}>
                  <Button type="link" linkTo={el.route} className="footer-link-button">
                    {getText(el.title)}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xl={3} lg={3} md={12} xs={12}>
            <p className="footer-label">{getText('keepInTouch')}</p>
            <a href="mailto: support@onevita.eu" className="touch-text">
              support@onevita.eu
            </a>
          </Col>
        </Row>

        <div className="online-container">
          <p className="footer-label">{getText('oneVitaOnline')}</p>
          <p className="online-description">{getText('findUsOnlineAndFollowUs')}</p>
          <div className="social-container">
            {onlineVita?.map((el, ind) => (
              <div key={ind} role="button" tabIndex={0} onClick={() => window.open(el.route, '_blank')}>
                <Image src={el.icon} width={55} height={55} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="footer-container">
        <div className="logo-country-container">
          <Link to={ROUTES.Home} className="logo-container">
            <Logo className="logo" />
          </Link>
          <Dropdown
            small
            noClear
            value={geoLocation?.geoLocation?.country}
            options={geoLocation?.countries}
            displayKey="name"
            className="dropdown"
            onChange={handleChangeCountry}
            mapValue={(val) => val?.name}
          />
        </div>

        <div className="terms-rights-container">
          <p className="text">
            &nbsp; © {getYear}, <span>{getText('oneVitaAllRightsReserved')}</span>
          </p>
          <Button type="link" onClick={() => history.push(ROUTES.TermsAndConditions)} className="button">
            {getText('termsAndConditions')}
          </Button>
          <Button type="link" onClick={() => history.push(ROUTES.PrivacyPolicy)} className="button">
            {getText('privacyPolicy')}
          </Button>
          <Button type="link" onClick={() => history.push(ROUTES.HelpPage)} className="button">
            {getText('delivery')}
          </Button>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export default Footer;
