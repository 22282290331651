import { theme, commonStyles } from 'styles';

export const slider = ({ width, height, imageHeight }) => ({
  position: 'relative',
  width: width || '100%',
  height: height || 'auto',
  overflow: 'hidden',
  borderRadius: 10,

  '.all-photos-button': {
    border: 'none',

    '.action': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      borderRadius: '0 0 10px 0',

      '.label': {
        marginRight: 4,
        marginBottom: 8,
        color: theme.white,
        fontWeight: 500,
      },

      '.icon': {
        marginRight: 4,
        marginBottom: 4,
      },
    },
  },
  '.arrows-container': {
    '.left-arrow': {
      left: 5,
      position: 'absolute',
      top: '45%',
      color: theme.gray400,
      backgroundColor: theme.white,
      borderRadius: '50%',
      fontSize: 24,
    },

    '.right-arrow': {
      right: 5,
      position: 'absolute',
      top: '45%',
      color: theme.gray400,
      backgroundColor: theme.white,
      borderRadius: '50%',
    },
  },

  '.images': {
    borderRadius: 10,
    height: imageHeight ?? 'auto',
    position: 'relative',

    '.fullscreen-icon': {
      position: 'absolute',
      bottom: 10,
      right: 10,
    },
  },
});

export const sliderWrapper = {
  transition: 'transform ease-out 0.45s',
  position: 'relative',
  height: '100%',
  display: 'flex',

  '& > *': {
    height: '100%',
    width: '100%',
    flex: '1 0 100%',
  },
};

export const dotsContainer = (withShowAllImages) => [
  {
    position: 'absolute',
    bottom: 4,
    left: withShowAllImages ? '15%' : '50%',
    transform: 'translateX(-50%)',
    overflow: 'hidden',
    width: 60,

    '.dots-list': {
      display: 'flex',
      flexWrap: 'nowrap',
      listStyle: 'none',
      transition: 'transform 0.45s linear',
    },
  },
];

export const indicatorDot = (isActive) => ({
  backgroundColor: isActive ? theme.black : theme.white,
  border: `1px solid ${theme.gray200}`,
  width: 8,
  minWidth: 8,
  flex: '0 0 8px',
  height: 8,
  borderRadius: '50%',
  margin: '0 2px',
  cursor: 'pointer',
  transition: 'background-color 0.45s ease-in',
});

export const imageStyles = (props) => [...commonStyles(props)];

export const arrowsLeft = (isDisabled) => [
  {
    cursor: isDisabled && 'not-allowed',
    opacity: isDisabled ? 0.4 : 1,
  },

  isDisabled && {
    '& *': { pointerEvents: 'none' },
  },
];
