import queryString from 'query-string';
import { getReq } from 'services/axios';
import { myProfileModel } from 'services';

export const getConfig = (values) => {
  const params = queryString.stringify({ key: values });

  return getReq(`${apiUrls.lifeApiUrl}/config/get?${params}`);
};

export const getOneLifeProfile = async () => {
  const [data, err] = await getReq(`${apiUrls.lifeApiUrl}/profile/v2/my`);
  return [data && myProfileModel(data), err];
};
