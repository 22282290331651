import { theme } from 'styles';

export const productsContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',

  '.products-badge-container': {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    marginBottom: 24,

    '.column-cart': {
      display: 'flex',
      width: '100%',
    },
  },

  '.arrow': {
    color: theme.gray200,
  },
};

export const dotsContainer = {
  position: 'absolute',
  bottom: 4,
  left: '50%',
  transform: 'translateX(-50%)',
  overflow: 'hidden',
  width: 70,

  '.dots-list': {
    display: 'flex',
    flexWrap: 'nowrap',
    listStyle: 'none',
    transition: 'transform 0.45s linear',
  },
};

export const indicatorDot = (isActive) => ({
  backgroundColor: isActive ? theme.black : theme.gray600,
  width: 14,
  minWidth: 14,
  flex: '0 0 14px',
  height: 14,
  borderRadius: '50%',
  margin: '0 2px',
  cursor: 'pointer',
  transition: 'background-color 0.45s ease-in',
});
