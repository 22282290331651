import { theme } from 'styles';

export const carouselProductsContainer = {
  '.products-info-home-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 500,
    paddingRight: 84,

    '.title': {
      fontSize: 32,
      fontWeight: 400,
      marginBottom: 8,
    },

    '.horizontal-line': {
      backgroundColor: theme.black,
      width: '100%',
      marginBottom: 16,
    },

    '.description': {
      fontSize: 20,
      fontWeight: 300,
      marginBottom: 48,
    },

    '.product-button': {
      textTransform: 'uppercase',
      marginBottom: 36,
      backgroundColor: 'transparent',
      color: theme.gray500,
      border: `1px solid ${theme.grayLight}`,
      fontSize: 16,
      fontWeight: 400,
      width: 'fit-content',

      '&:active': { outline: 'none' },

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },

    '@media (max-width: 767px)': {
      width: '100%',
      paddingRight: 0,
    },
  },

  '.carousel-container-home': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.step-container': {
      position: 'absolute',
      left: '25%',
      right: '0%',
      top: 70,
      bottom: '50%',
      zIndex: 100,
      color: theme.white,
      fontWeight: 400,

      '.step': {
        fontSize: 180,
      },
    },

    '.carousel-products': {
      height: 450,

      '.arrows-container': {
        display: 'flex',
        alignItems: 'center',

        '.left-arrow': {
          left: '70%',
          bottom: 0,
          top: '93%',
          border: `1px solid ${theme.gray200}`,
          width: 20,
          height: 20,
          fontSize: 20,
          borderRadius: '50%',
        },

        '.right-arrow': {
          bottom: 20,
          right: 75,
          top: '93%',
          border: `1px solid ${theme.gray200}`,
          width: 20,
          height: 20,
          fontSize: 20,
          borderRadius: '50%',
        },
      },

      '.dots-container': {
        bottom: 20,
        left: '30%',
      },
    },
  },
};
