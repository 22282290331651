import { getText } from '@veraio/strank';
import { Button } from 'components';
import { ROUTES } from 'enums';
import { affiliateContainer } from './styles';

const BecomeAffiliateCart = () => (
  <div css={affiliateContainer}>
    <p className="upper-text">{getText('unlockExclusivePerks')}</p>
    <h4 className="affiliate-title">{getText('becomeAnAffiliateOfOneVita')}</h4>
    <Button type="secondary" className="affiliate-button" linkTo={ROUTES.BecomeAnAffiliate}>
      {getText('learnHow')}
    </Button>
  </div>
);

export default BecomeAffiliateCart;
