import { theme } from 'styles';

export const inputField = {
  display: 'none',
};

export const uploadWrap = (hasValidation, { multiple, disabled, small }, hasError) => [
  {
    color: theme.black,
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: hasValidation ? 6 : 0,
    mimHeight: small ? '3.6rem' : '4.4rem',
    padding: multiple ? 0 : '1em',
    background: theme.secondaryDarkLight,
    border: multiple ? 'none' : hasError ? `1px solid ${theme.error}` : `1px solid ${theme.gray100}`,

    '& img': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '4.4rem',
    },

    '.button': {
      color: theme.black,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.upload-image': {
        width: 32,
        height: 32,
        marginBottom: 4,
      },

      '.upload-label': {
        textAlign: 'center',
      },
    },
  },

  disabled && {
    borderColor: theme.gray100,
    color: theme.gray100,
    cursor: 'not-allowed',
  },
];

export const itemImageWrap = {
  backgroundColor: theme.white,
  padding: '0.4em',
  borderRadius: 6,
};

export const fileInputContent = ({ multiple }) => ({
  width: '100%',
  padding: multiple ? '0.8em 1em' : 0,
  borderRadius: multiple ? 6 : 0,
  background: 'transparent',
  border: multiple ? `1px solid ${theme.gray100}` : 'none',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  marginTop: multiple ? 8 : 0,
  flexDirection: 'column',
  position: 'relative',

  '.remove-icon': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export const fileInputLabel = ({ small }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
  textOverflow: 'ellipsis',
});
