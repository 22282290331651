import { theme } from 'styles';

export const tableContainer = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  borderRadius: 3,
  padding: 16,

  '.container-body': {
    marginBottom: 15,
  },
};

export const filterBarContainer = {
  position: 'relative',
  padding: 16,
};

export const tableHead = {
  display: 'flex',
  flexFlow: 'row nowrap',
};

export const tableRow = (hasOnClick, selectRow) => [
  {
    display: 'flex',
    alignItems: 'center',
    color: theme.textLightPrimary,
    transition: 'background-color .3s ease-in-out',
    borderBottom: `1px solid ${theme.gray100}`,
    padding: '0 1.5em',

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&:hover': {
      backgroundColor: theme.gray100,
    },
  },
  hasOnClick && {
    cursor: 'pointer',
  },
  selectRow && {
    backgroundColor: theme.gray100,
    '&:hover': {
      backgroundColor: theme.gray100,
    },
  },
];

export const tableCell = ({ width, flex, sm, md, lg, xl }) => [
  {
    flex: flex ?? (width ? `0 0 ${width}` : '1 0 60px'),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.5em 0.7em',
    outline: 'none',
    wordBreak: 'break-word',
  },
  sm && {
    '@media (max-width: 575px)': {
      display: 'none',
    },
  },
  md && {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  lg && {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  xl && {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
  },
];

export const tableHeadCell = (options) => [
  tableCell(options),
  {
    fontSize: 14,
    color: theme.gray300,
    backgroundColor: theme.ui_hover_rgba,
    padding: '16px 8px',

    '& i': {
      color: theme.gray400,
    },

    '& > p': {
      marginBottom: 0,
    },

    '&:hover': {
      '& i': {
        color: theme.primary_blue,
      },
    },
  },
  options?.sortable && {
    cursor: 'default',
    '& b': {
      cursor: 'pointer',
      '&:hover + i': {
        opacity: 1,
      },
    },
  },
];

export const tableHeadIcon = (isAsc, isDesc) => ({
  fontSize: 18,
  opacity: isAsc || isDesc ? 1 : 0,
  transform: `rotate(${isDesc ? 0 : 180}deg)`,
  transition: 'transform 300ms',
});

export const tableCellEmpty = {
  width: 32,
  height: 4,
  backgroundColor: theme.gray100,
  borderRadius: 12,
};

export const noResultsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '80px 20px',
  textAlign: 'center',
  flexDirection: 'column',
  color: theme.gray400,

  '.no-results-icon': {
    width: 64,
    height: 44,
    marginBottom: 16,
  },

  '.no-results-title': {
    fontWeight: 500,
    marginBottom: 12,
  },

  '.no-results-subtitle': {
    color: theme.black100,
  },
};
